export const HEARTRATE_MIN = 50
export const HEARTRATE_MAX = 200

export const RESISTANCE_POWER_TARGET_MAX = 1200
export const RESISTANCE_POWER_TARGET_STEP = 5

export const RESISTANCE_LEVEL_MAX = 100
export const RESISTANCE_LEVEL_STEP = 1

export const CADENCE_MAX = 120

export const POWER_MAX = 1000

export const TORQUE_MAX = 200

export const valueAsPercentage = (
  value: number,
  max: number,
  min = 0,
): number => {
  if (value - min >= 0) {
    return ((value - min) / (max - min)) * 100
  } else {
    return 0
  }
}
